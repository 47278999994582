import { toast } from "react-toastify";
import { NibiruTxClient, NibiruQuerier } from "@nibiruchain/nibijs";
import {
  setTxConfirmed,
  setTxFailed,
  setTxPending,
} from "../../ReduxSlices/ModalSlice";
import { setTokenUpdate } from "../../ReduxSlices/CommunicationSlice";
import { setEventToEmit } from "../../ReduxSlices/NotificationSlice";
import { api } from "./Api";
import { getIdFromHash } from "./Functions";

export const executeContract = async (
  emailData,
  funds,
  currentTime,
  chainId,
  endpt,
  contractAddr,
  dispatch,
  token_id,
  receiver,
  CONTRACT_MESSAGE,
  account,
  walletEx,
  tokenToSend
) => {
  try {
    toast.loading("Transaction is pending...");

    dispatch(setTxPending());
    await window[walletEx].enable(chainId);
    const signer = await window[walletEx].getOfflineSigner(chainId);
    const signingClient = await NibiruTxClient.connectWithSigner(endpt, signer);
    let hash;
    if (tokenToSend) {
      const tx = await signingClient.wasmClient.execute(
        account,
        contractAddr,
        CONTRACT_MESSAGE,
        "auto",
        "codedestate",
        [
          {
            amount: (
              Number(tokenToSend[0].amount) *
              10 ** process.env.REACT_APP_USDC_DECIMALS
            ).toString(),
            denom: tokenToSend[0].denom,
          },
        ]
      );
      hash = tx.transactionHash;
    } else {
      const tx = await signingClient.wasmClient.execute(
        account,
        contractAddr,
        CONTRACT_MESSAGE,
        "auto"
      );
      hash = tx.transactionHash;
    }
    dispatch(setTxConfirmed());
    toast.dismiss();
    toast.success("Transaction is confirmed!", {
      autoClose: 1000,
    });
    toast.loading("Updating assets with new data..");
    if (token_id) {
      dispatch(setTokenUpdate({ token_id: token_id, contract: contractAddr }));
      if (receiver !== account && hash) {
        dispatch(
          setEventToEmit({
            txHash: hash,
            action: Object.keys(CONTRACT_MESSAGE)[0],
            token_id: token_id,
            receiver: receiver,
            sender: account,
            timeUTC: currentTime,
            funds: funds,
          })
        );
        const res = await api("user/addEventItem", {
          txHash: hash,
          action: Object.keys(CONTRACT_MESSAGE)[0],
          token_id: token_id,
          receiver: receiver,
          sender: account,
          timeUTC: currentTime,
          funds: funds,
          emailData: emailData,
        });
      }
    }
    toast.dismiss();
    toast.success("Assets updated with new data.", {
      autoClose: 2000,
    });
    return hash;
  } catch (error) {
    dispatch(setTxFailed());

    const errorText = error.toString();
    console.log(error.toString());
    toast.dismiss();

    if (errorText.includes("does not exist on chain"))
      toast.error(`Not enough NIBI in Wallet to pay for gas fees`, {
        autoClose: 1000,
      });
    if (errorText.includes("is too short"))
      toast.error(
        `Your requested stay is shorter than the host´s minimum stay`,
        {
          autoClose: 1000,
        }
      );
    else {
      toast.error(
        `Transaction failed with following errors ${error.toString()}`,
        {
          autoClose: 3000,
        }
      );
    }
    return false;
  }
};

export const queryContract = async (contractAddr, CONTRACT_MESSAGE, endpt) => {
  try {
    const querier = await NibiruQuerier.connect(endpt);
    const res = await querier.nibiruExtensions.wasm.queryContractSmart(
      contractAddr,
      CONTRACT_MESSAGE
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
